import {IUser} from "./userservice";

export class WishService {

    async getWishes(user: IUser): Promise<IWish[]> {
        const requestOptions: RequestInit = {
            method: 'GET',
            headers: {'Content-Type': 'application/json', 'Time-Zone': 'Europe/Berlin'},
            credentials: 'same-origin'
        };
        return fetch('/api/wish/?id=' + user.id, requestOptions)
            .then(response => response.json())
            .then(json => json as IWish[])
    }

    async addWish(user: number, article: string, description: string) {
        const requestOptions: RequestInit = {
            method: 'POST',
            headers: {'Content-Type': 'application/json', 'Time-Zone': 'Europe/Berlin'},
            credentials: 'same-origin',
            body: JSON.stringify({user, article, description})
        };
        return fetch('/api/wish/', requestOptions)
    }

    async updateWish(wish: number, user: number, article: string, description: string) {
        const requestOptions: RequestInit = {
            method: 'POST',
            headers: {'Content-Type': 'application/json', 'Time-Zone': 'Europe/Berlin'},
            credentials: 'same-origin',
            body: JSON.stringify({user, article, description})
        };
        return fetch('/api/wish/?id=' + wish, requestOptions)
    }

    async deleteWish(wish: number) {
        const requestOptions: RequestInit = {
            method: 'DELETE',
            headers: {'Content-Type': 'application/json', 'Time-Zone': 'Europe/Berlin'},
            credentials: 'same-origin'
        };
        return fetch('/api/wish/?id=' + wish, requestOptions)
    }
}

export interface IWish {
    id: number;
    name: string;
    description: string;
    user_id: number;
}
