export class UserService {

    async getUsers(): Promise<IUser[]> {
        const requestOptions: RequestInit = {
            method: 'GET',
            headers: {'Content-Type': 'application/json', 'Time-Zone': 'Europe/Berlin'},
            credentials: 'same-origin'
        };
        return fetch('/api/user/', requestOptions)
            .then(response => response.json())
            .then(json => json as IUser[])
    }
}

export interface IUser {
    id: number;
    name: string;
}
