import React, {useEffect, useState} from 'react';
import 'semantic-ui-css/semantic.min.css'
import './App.css';
import {Button, Container, Dimmer, Dropdown, Header, Icon, Loader, Segment, Table, TableHeader} from 'semantic-ui-react'
import {IUser, UserService} from "./userservice";
import {IWish, WishService} from "./wishservice";
import {WishModal} from "./wishModal";
import {LinkItUrl} from "react-linkify-it";
import {DropdownItemProps} from "semantic-ui-react/dist/commonjs/modules/Dropdown/DropdownItem";
import {useMediaQuery} from "./useMediaQuery";

function App() {
    const [users, setUsers] = useState<IUser[]>([]);
    const [wishes, setWishes] = useState(new Map<IUser, IWish[]>());
    const [options, setOptions] = useState<DropdownItemProps[]>([]);
    const [selectedUser, setSelectedUser] = useState<number>(-1);
    const [selectedWish, setSelectedWish] = useState<IWish>();
    const [loading, setLoading] = useState<boolean>(true);
    const [openModal, setOpenModal] = useState<boolean>(false);

    const userService = new UserService();
    const wishService = new WishService();
    const defaultOption: DropdownItemProps = {key: 'all', value: -1, text: 'Allen'};
    const isMobile = useMediaQuery('(max-width: 786px)')
    const isTablet = useMediaQuery('(max-width: 1200px)')

    useEffect(() => {
        userService.getUsers().then(user => setUsers(user));
    }, [])

    const updateUser = () => {
        Promise.all(users.map(user => wishService.getWishes(user).then(userWishes => {
            setWishes(new Map(wishes.set(user, userWishes)));
        }))).then(() => {
            if (users.length !== 0) setLoading(false);
        });
    }
    useEffect(() => {
        updateUser();
        const options = [defaultOption].concat(users.map(u => ({
            key: u.id,
            value: u.id,
            text: u.name
        } as DropdownItemProps)))
        setOptions(options);
    }, [users])

    const onCloseModal = () => {
        setSelectedWish(undefined);
        setOpenModal(false);
    }
    return (
        <div className="App">
            <Container>
            <Dimmer active={loading}>
                <Loader size='massive'>Wünsche werden geladen</Loader>
            </Dimmer>
            <img className='message' src="images/message.png" />
            <br/>
            <div hidden={loading}>
                <WishModal onClose={onCloseModal} onOpen={() => setOpenModal(true)} open={openModal}
                           wish={selectedWish} onSave={updateUser}/>
                <br/>
                <Header as='h3' inverted>
                    Wünsche von&nbsp;
                    <Header as='h2' inverted>
                        <Dropdown floating inline options={options} defaultValue={-1}
                                  placeholder='Filter Wünschender'
                                  width={12} onChange={
                            (e, {name, value}) => {
                                setSelectedUser(value as number);
                            }}
                        />
                    </Header>
                </Header>
                {users.map(user => {
                    return selectedUser === -1 || user.id === selectedUser ? (<Segment inverted stacked>
                        <Header as='h2' textAlign='center'>
                            🎄 {user.name} 🎄
                        </Header>
                        <Table padded inverted fixed>
                            <TableHeader>
                                <Table.Row>
                                    <Table.HeaderCell width='6'>Artikel</Table.HeaderCell>
                                    <Table.HeaderCell width='6'>Beschreibung</Table.HeaderCell>
                                    <Table.HeaderCell width={isTablet ? '5' : isMobile ? '6' : '3'}>Finden (Vorschläge)</Table.HeaderCell>
                                </Table.Row>
                            </TableHeader>
                            <Table.Body>
                                {wishes.get(user)?.map((wish, index) =>
                                    <Table.Row>
                                        <Table.Cell>{wish.name}</Table.Cell>
                                        <Table.Cell><LinkItUrl>{wish.description}</LinkItUrl></Table.Cell>
                                        <Table.Cell>
                                            <Button.Group>
                                                <Button icon color='yellow' target='_blank'
                                                        href={'https://www.amazon.de/s?k=' + encodeURI(wish.name)}>
                                                    <Icon name='amazon'/>
                                                </Button>
                                                <Button icon color='google plus' target='_blank'
                                                        href={'https://www.google.com/search?q=' + encodeURI(wish.name) + "&tbm=shop"}>
                                                    <Icon name='google'/>
                                                </Button>
                                                <Button icon color='blue' target='_blank'
                                                        href={'https://www.idealo.de/preisvergleich/MainSearchProductCategory.html?q=' + encodeURI(wish.name)}>
                                                    <Icon name='shopping cart'/>
                                                </Button>
                                            </Button.Group>
                                            <Button color='blue' icon content='Bearbeiten' className='right-floating'
                                                    onClick={() => {
                                                        setSelectedWish(wish);
                                                        setOpenModal(true);
                                                    }}>
                                                <Icon name='pencil'/>
                                            </Button>
                                        </Table.Cell>
                                    </Table.Row>)}
                            </Table.Body>
                        </Table>
                    </Segment>) : null
                })}
            </div>
            </Container>
            <div hidden={loading}>
                <img className='bottom-right' src='images/bottom.png'/>
            </div>
        </div>
    );
}

export default App;
