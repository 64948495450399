import React, {useEffect, useState} from "react";
import {Button, Form, Icon, Modal} from "semantic-ui-react";
import {IUser, UserService} from "./userservice";
import {DropdownItemProps} from "semantic-ui-react/dist/commonjs/modules/Dropdown/DropdownItem";
import {IWish, WishService} from "./wishservice";

interface IWishModalProps {
    onSave: () => void;
    wish?: IWish;
    open?: boolean;
    onClose: () => void;
    onOpen: () => void;
}

export function WishModal(props: IWishModalProps) {
    const [options, setOptions] = useState<DropdownItemProps[]>([]);
    const [user, setUser] = useState<IUser[]>([]);

    const [selectedUser, setSelectedUser] = useState<number>();
    const [article, setArticle] = useState<string>('');
    const [description, setDescription] = useState<string>('');

    const [openConfirmation, setOpenConfirmation] = useState<boolean>(false);

    const userService = new UserService();
    const wishService = new WishService();

    useEffect(() => {
        userService.getUsers().then(u => setUser(u));
    }, [])

    useEffect(() => {
        setSelectedUser(props.wish?.user_id)
        setArticle(props.wish?.name ? props.wish?.name : '')
        setDescription(props.wish?.description ? props.wish?.description : '')

    }, [props.wish])

    useEffect(() => {
        const options = user.map(u => ({key: u.id, value: u.id, text: u.name}))
        setOptions(options)
    }, [user])

    return (
        <Modal
            size='small'
            onClose={() => props.onClose()}
            onOpen={() => props.onOpen()}
            open={props.open}
            trigger={<Button className='menu-button' color='red' size='big'>
                🎁 Neuen Wunsch hinzufügen
            </Button>}>
            <Modal.Header as='span'>🌟 {props.wish ? 'Wunsch bearbeiten' : 'Neuen Wunsch hinzufügen'} 🌟</Modal.Header>
            <Modal.Content>
                <Modal.Description>
                    <Form>
                        <Form.Group>
                            <Form.Dropdown selection fluid scrolling options={options} value={selectedUser} label='Wunsch von' placeholder='Wunsch von'
                                           width={12} onChange={
                                (e, {_name, value}) => {
                                    setSelectedUser(value as number);
                                }
                            }/>
                        </Form.Group>
                        <Form.Group>
                            <Form.Input value={article} label='Artikel' placeholder='Artikel' width={12}
                                        onChange={event => setArticle(event.target.value)}/>
                        </Form.Group>
                        <Form.Group>
                            <Form.TextArea value={description} label='Beschreibung' placeholder='Beschreibung' width={12}
                                           onChange={event => setDescription(event.target.value)}/>
                        </Form.Group>
                    </Form>
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
                <div>
                    <Button content='Abbrechen' color='red' labelPosition='left' icon='cancel' onClick={() => {
                        setSelectedUser(undefined);
                        setArticle('');
                        setDescription('');
                        props.onClose();
                    }}/>
                    <Button
                        content="Speichern"
                        labelPosition='right'
                        icon='checkmark'
                        onClick={() => {
                            if (!selectedUser) {
                                return;
                            }

                            if (props.wish) {
                                wishService.updateWish(props.wish.id, selectedUser, article, description).then(() => props.onSave())
                            } else {
                                wishService.addWish(selectedUser, article, description).then(() => props.onSave());
                            }
                            setSelectedUser(undefined);
                            setArticle('');
                            setDescription('');
                            props.onClose();
                        }}
                        positive
                    />
                    {
                        props.wish ?
                            <Modal
                                size='small'
                                basic
                                open={openConfirmation}
                                onOpen={() => setOpenConfirmation(true)}
                                onClose={() => setOpenConfirmation(false)}
                                trigger={<Button
                                    big
                                    icon='trash alternate'
                                    color='red'
                                    basic
                                    className='left-floating'/>}>
                                <Modal.Header icon>
                                    <Icon name='trash alternate'/>
                                    Wunsch wirklich löschen ?
                                </Modal.Header>
                                <Modal.Content>
                                    <p>
                                        Soll {user.find(u => u.id === selectedUser)?.name}s Wunsch '{props.wish.name}' wirklich
                                        gelöscht
                                        werden?
                                    </p>
                                </Modal.Content>
                                <Modal.Actions>
                                    <div>
                                        <Button content='Abbrechen' attached='left' color='red' labelPosition='left' icon='cancel'
                                                onClick={() => {
                                                   setOpenConfirmation(false);
                                                }}/>
                                        <Button content='Löschen' attached='right' color='green' labelPosition='right'
                                                icon='checkmark'
                                                onClick={() => {
                                                    wishService.deleteWish(props.wish!.id!).then(() => props.onSave());
                                                    setSelectedUser(undefined);
                                                    setArticle('');
                                                    setDescription('');
                                                    setOpenConfirmation(false);
                                                    props.onClose();
                                                }}/>
                                    </div>
                                </Modal.Actions>
                            </Modal> : null
                    }
                </div>
                <br/>
            </Modal.Actions>
        </Modal>
    )

}
